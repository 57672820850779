/** 
 * import : 
 * 
 * @version 0.1 (2021.06.28 : usayama)
 * @version 0.2 (2021.09.27 : usayama) // InputSelect, contact_subject 追加
*/
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// conf, action
import { contact_subject } from '@config/app.config'
import supportActions from '@redux/support/actions'
import { VALIDATION_PATTERNS } from '@config/validation.patterns'
// components
import ModalLoading from '@components/UIKit/ModalLoading'
import Label from '@components/Typo/Label'
import Alert from '@components/UIKit/Alert'
import Button from '@components/UIKit/Button'
import Input from '@components/Form/Input'
import InputSelect from '@components/Form/InputSelect'
import InputCheckbox from '@components/Form/InputCheckbox'
import Textarea from '@components/Form/Textarea'
// containers
// helper
// style, img, elm
// const
const { initContact, contact } = supportActions

/** 
 * const : 
 * 
 * @version 0.1 (2021.06.28 : usayama)
*/
export default function ContactFormContainer(props) {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.28 : usayama)
   */
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { loadContact, contactResult, contactResultStatus } = useSelector(state => state.Support)
  const { handleSubmit, register, formState, setError, errors } = useForm({ mode: 'onChange' })

  /** 
   * state : 
   * 
   * @version 0.1 (2021.06.28 : usayama)
  */
  const [initPage, setInitPage] = React.useState(false)

  /** 
   * handle : 
   * 
   * @version 0.1 (2021.06.28 : usayama)
   */
  const handleOnSubmit = (d) => dispatch(contact(d))

  /** 
   * useEffect
   * 
   * @version 0.1 (2021.06.28 : usayama)
   */
  React.useEffect(() => {
    if(contactResult && contactResult.errors) {
      Object.keys(contactResult.errors).map((key) => setError(key, { type: "manual", message: contactResult.errors[key] }))
    }
  }, [contactResult, setError])

  /** 
   * componentDidMount
   * https://reactjs.org/docs/hooks-effect.html
   * 
   * @version 0.1 (2021.06.28 : usayama)
   */
  React.useEffect(() => {
    if(!initPage) {
      dispatch(initContact())
      setInitPage(true)
    }
  }, [initPage, dispatch])

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.28 : usayama)
   * @version 0.2 (2021.06.30 : usayama) // className= に修正
   * @version 0.3 (2021.09.27 : usayama) // InputSelect 追加
   */
  return (
    <div className="">
      <ModalLoading isLoading={loadContact} />
      {
        contactResultStatus && 
        <Alert 
          status={contactResultStatus} 
          label={"message." + contactResultStatus + ".contact"} 
          isOpen={true} 
          closeBt={true}
        />
      }
      {
        (!contactResultStatus || contactResultStatus !== 'success') && 
          <form onSubmit={handleSubmit(handleOnSubmit)}>
            <div className="container wrap mt-2">
              <p className="mb-3"><Label label="text.contact" /></p>
              <div className="contact contact-form container mb-4">
                <div className="card">
                  <div className="card">
                    <div className="card-body">
                      <InputSelect
                        labelKey="contact_form"
                        options={contact_subject}
                        label="contact_subject"
                        name="subject"
                        errors={errors && errors.address_state}
                        className={errors.subject && "is-invalid"}
                        labelClassName="required"
                        inputRef={register({ required: true })}
                      />
                      <Input
                        labelKey="contact_form"
                        label="contact_name"
                        name="name"
                        errors={errors && errors.name}
                        className={errors.name && "is-invalid"}
                        labelClassName="required"
                        inputRef={register({ required: true })}
                      />
                      <Input
                        labelKey="contact_form"
                        label="contact_email"
                        name="email"
                        errors={errors && errors.email}
                        className={errors.email && "is-invalid"}
                        labelClassName="required"
                        inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.email })}
                      />
                      <Input
                        labelKey="contact_form"
                        label="contact_tel"
                        name="tel"
                        errors={errors && errors.tel}
                        className={errors.tel && "is-invalid"}
                        labelClassName="required"
                        inputRef={register({ required: true, pattern: VALIDATION_PATTERNS.tel })}
                      />
                      <Textarea 
                        labelKey="contact_form"
                        label="contact_text"
                        name="text"
                        rows={10}
                        errors={errors && errors.text}
                        className={errors.text && "is-invalid"}
                        labelClassName="required"
                        inputRef={register({ required: true })}
                      />
                      <div className="form-text mt-4">
                        <p dangerouslySetInnerHTML={{ __html: t('text.contact_agreement') }} />
                      </div>
                      <InputCheckbox 
                        id="agreement"
                        name="contact_agreement"
                        value="agree"
                        label="label.contact_agreement"
                        inputWrapClasseName="justify-content-center"
                        feedback={errors && errors.agreement}
                        errors={errors && errors.agreement}
                        className={errors.agreement && "is-invalid"}
                        inputClassName={errors.agreement && "is-invalid"}
                        inputRef={register({ required: true })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container wrap mt-2">
              <div className="contact container contact-action">
                <div className="form-action">
                  <Button type="submit" color="success" className="w-100" label="send" size="lg" disabled={!formState.isValid} />
                </div>
              </div>
            </div>
          </form>
      }
    </div>
  )
}
