/** 
 * import : 
 * 
 * @version 0.1 (2021.02.03 : usayama)
 * @version 0.2 (2021.02.12 : usayama) // useTranslation 追加
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
import { useTranslation } from 'react-i18next'
// conf, action
// components
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.02.03 : usayama)
*/
const Textarea = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.02.03 : usayama)
   * @version 0.2 (2021.02.12 : usayama) // useTranslation, placeholder 追加
   * @version 0.3 (2021.02.22 : usayama) // helpText, feedback 追加
   * @version 0.4 (2021.06.28 : usayama_dx) // リファクタリング
   */
  const { t } = useTranslation()
  const { className,
          labelClassName,
          labelKey,
          id,
          name,
          label,
          errors,
          helpText,
          feedback,
          inputRef,
          placeholder, ...attrs } = props
  const classes = classNames(
    "form-group",
    errors && "has-danger",
    className,
  )
  const inputClasses = classNames(
    "form-control",
    feedback && "is-" + feedback,
    errors && "form-control-danger",
    errors && "is-invalid"
  )
  const labelClasses = classNames(
    "mb-2",
    labelClassName,
  )

  /** 
   * return : 
   * 
   * @version 0.1 (2021.02.03 : usayama)
   * @version 0.2 (2021.02.12 : usayama) // placeholder 追加
   * @version 0.3 (2021.02.22 : usayama) // helpText, feedback 追加
   * @version 0.4 (2021.06.28 : usayama_dx) // リファクタリング
   */
  return (
    <div className={classes}>
      { label && <label className={labelClasses}>{t('label.' + label)}</label>}
      <textarea 
        id={id}
        name={name} 
        className={inputClasses} 
        placeholder={placeholder && t("placeholder." + placeholder)}
        ref={inputRef}
        {...attrs}
      />
      { helpText && <small className="form-text text-muted">{t(helpText)}</small> }
      { errors && <div className="form-text text-danger">{t('message.error.' + labelKey + '.' + name)}</div> }
      { feedback && <div className={feedback.status + "-feedback"}>{t(feedback.text)}</div> }
    </div>

  )
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.02.03 : usayama)
 * @version 0.2 (2021.02.12 : usayama) // placeholder 追加
 * @version 0.3 (2021.02.22 : usayama) // helpText, feedback 追加
 * @version 0.4 (2021.06.28 : usayama) // labelClassName, labelKey 追加
*/
Textarea.propTypes = {
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  labelKey: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  errors: PropTypes.object,
  helpText: PropTypes.string,
  feedback: PropTypes.object,
  inputRef: PropTypes.func,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.02.03 : usayama)
 * @version 0.2 (2021.02.12 : usayama) // placeholder 追加
 * @version 0.3 (2021.02.22 : usayama) // helpText, feedback 追加
 * @version 0.4 (2021.06.28 : usayama) // labelClassName, labelKey 追加
*/
Textarea.defaultProps = {
  className: "",
  labelClassName: "",
  labelKey: "",
  id: "",
  name: "",
  label: "",
  placeholder: "",
  errors: null,
  helpText: null,
  feedback: null,
  inputRef: null,
}

/** 
 * export
 * 
 * @version 0.1 (2021.02.03 : usayama)
*/
export default Textarea
