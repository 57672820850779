/** 
 * import : 
 * 
 * @version 0.1 (2021.03.02 : usayama)
 * @version 0.2 (2021.05.29 : usayama) // Link 削除Label 削除
*/
import React from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
import { useTranslation } from 'react-i18next'
// conf, action
// components
// helper
// style, img
// consts

/** 
 * const : 
 * 
 * @version 0.1 (2021.03.02 : usayama)
*/
const InputCheckbox = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.03.02 : usayama)
   * @version 0.2 (2021.03.22 : usayama) // d-flex align-items-center form-check を div に配置
   * @version 0.3 (2021.05.20 : usayama) // inputWrapClasseName 追加
   */
  const { t } = useTranslation()
  const { className,
          inputWrapClasseName,
          size,
          id,
          name,
          type,
          label,
          suffix,
          placeholder,
          helpText,
          readonly,
          feedback,
          inputRef,
          disabled, 
          errors,  ...attrs } = props
  const classes = classNames(
    className,
    errors && "has-danger"
  )
  const inputWrapClasses = classNames(
    "d-flex",
    "align-items-center",
    "form-check",
    inputWrapClasseName,
  )
  const inputClasses = classNames(
    "me-2",
    "form-check-input",
//    size && "form-control-" + size,
    feedback && "is-" + feedback,
    errors && "form-control-danger"
  )
  const labelClasses = classNames(
    "form-check-label",
    errors && "text-danger"
  )

  /** 
   * return : 
   * 
   * @version 0.1 (2021.03.02 : usayama)
   * @version 0.2 (2021.03.22 : usayama) // classes 箇所変更
   * @version 0.3 (2021.05.20 : usayama) // inputWrapClasses に変更, Label 削除
   */
  return (
    <div className={classes}>
      <div className={inputWrapClasses}>
        <input 
          type={type} 
          id={id}
          name={name} 
          className={inputClasses} 
          placeholder={placeholder && t(placeholder)}
          readOnly={readonly}
          disabled={disabled}
          ref={inputRef}
          {...attrs}
         />
        { label && <label htmlFor={id} className={labelClasses}>{t(label)}</label>}
      </div>
      { suffix && <div className="ml-2">{t(suffix)}</div> } 
      { helpText && <small className="form-text text-muted">{t(helpText)}</small> }
      { errors && <div className="form-text text-danger">{t('message.error.' + name)}</div> }
      { feedback && <div className={feedback.status + "-feedback"}>{t(feedback.text)}</div> }
    </div>
  )
}

/** 
 * propTypes
 * 
 * @version 0.1 (2021.03.02 : usayama)
 * @version 0.3 (2021.05.20 : usayama) // inputWrapClasseName 追加
*/
InputCheckbox.propTypes = {
  className: PropTypes.string,
  inputWrapClasseName: PropTypes.string,
  size: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  suffix: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  feedback: PropTypes.object,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  inputRef: PropTypes.func,
}

/** 
 * defaultProps
 * 
 * @version 0.1 (2021.03.02 : usayama)
 * @version 0.2 (2021.02.15 : usayama) // suffix 追加
 * @version 0.3 (2021.02.22 : usayama) // inputRef 追加
 * @version 0.3 (2021.05.20 : usayama) // inputWrapClasseName 追加
*/
InputCheckbox.defaultProps = {
  className: "",
  inputWrapClasseName: "",
  size: null,
  id: null,
  name: "",
  type: "checkbox",
  label: null,
  suffix: null,
  placeholder: null,
  helpText: null,
  feedback: null,
  readonly: false,
  disabled: false,
  inputRef: null 
}

/** 
 * export
 * 
 * @version 0.1 (2021.03.02 : usayama)
*/
export default InputCheckbox
