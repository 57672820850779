/**
 * import
 *
 * @version 0.1 (2021.06.28 : usayama)
 */
import React from 'react'
import { useTranslation } from 'react-i18next'
// conf, action
// components
import Seo from '@components/Tools/Seo'
// containers
import LayoutContainer from '@containers/LayoutContainer'
import TextBasePageContainer from '@containers/TextBasePageContainer'
import ContactFormContainer from '@containers/ContactFormContainer'
// helper
// style, img, elm
// const

/**
 * const
 *
 * @version 0.1 (2021.06.28 : usayama)
 */
const Contact = props => {

  /** 
   * const : 
   * 
   * @version 0.1 (2021.06.28 : usayama_dx)
   */
  const { t } = useTranslation()

  /** 
   * return : 
   * 
   * @version 0.1 (2021.06.28 : usayama)
   * @version 0.2 (2021.09.27 : usayama) // 余白調整
   */
	return (
    <LayoutContainer>
      <Seo title={t('title.contact')} />
      <TextBasePageContainer className="contact pt-1 ps-3 pe-3" title="contact">
        <ContactFormContainer />
      </TextBasePageContainer>
	 </LayoutContainer>
  )
}

/**
 * export
 *
 * @version 0.1 (2021.06.28 : usayama)
 */
export default Contact
